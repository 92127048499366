
import React from "react";

import logo from './logo.svg';
import warning from './warning.svg';

class RegistrationDialog extends React.Component {

  state = {
    firstname: '',
    lastname: '',
    email: '',
    privacy: false
  }


  componentDidUpdate(prevProps) {

    if (prevProps.open !== this.props.open) {
      console.log("Resetting form");
      this.setState({
        firstname: '',
        lastname: '',
        email: '',
        privacy: false,
        error: ''
      });
    }
  }


  render() {

    const classes = ['RegistrationDialog'];

    if (this.props.open) {
      classes.push('open');
    }

    return (
      <div className={classes.join(' ')}>
        <h1>Ricevi un mese di abbonamento digitale Gratis a</h1>
        <div className="mb mt"><img src={logo} className="RegistrationDialog-logo" alt="logo" /></div>
        <div>Inserisci i tuoi dati.</div>
        <div className="mt mb"><input type="text" placeholder="Nome" value={this.state.firstname} onChange={ (e) => this.setState({ firstname: e.target.value })}></input></div>
        <div className="mb"><input type="text" placeholder="Cognome" value={this.state.lastname} onChange={ (e) => this.setState({ lastname: e.target.value })}></input></div>
        <div className="mb"><input type="email" placeholder="email" value={this.state.email} onChange={ (e) => this.setState({ email: e.target.value })}></input></div>
        <div className="mb-2"><label className="sm"><input type="checkbox" value="accepted" checked={ this.state.privacy } onChange={ (e) => this.setState({ privacy: e.target.checked })}></input> Ho letto e accetto l'informativa relativa al Trattamento dei Dati Personali ai sensi del Regolamento UE 2016/679 artt. 13 e 14. (*)</label></div>
        { this.state.error ? <div className="form-error mb"><img src={warning} className="RegistrationDialog-warning" alt="attenzione!" /> { this.state.error }</div> : null }
        <div className="mb"><button onClick={this.sendForm}>Invia</button></div>
        <div className="mb nothankyou" onClick={this.props.onClose}>Non ora, grazie.</div>

      </div>
    );

  }


  sendForm = () => {

    const data = {...this.state};
    if (data.firstname.trim().length === 0 ||
    data.lastname.trim().length === 0 ||
    data.email.trim().length === 0) {
      this.setState({ error: 'Per favore compila tutti i campi.'});
      return;
    }

    if (data.privacy === false) {
      this.setState({ error: 'Per favore conferma di aver preso visione della nota sulla privacy.'});
      return;
    }

    this.props.onClose();
  }

}

export default RegistrationDialog;