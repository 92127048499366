
import React, { useState, useEffect } from "react";

import HTMLFlipBook from "react-pageflip";
import RegistrationDialog from "./RegistrationDialog";

class Magazine extends React.Component {

  pageFlip = undefined;

  state = {
    width: -1,
    height: -1,
    orientation: undefined,
    registrationOpen: false,
    flips: 0
  }

  componentDidMount() {
    // When the component is mounted, add your DOM listener to the "nv" elem.
    // (The "nv" elem is assigned in the render function.)
    window.addEventListener("resize", this.handleWindowResize);
    this.handleWindowResize();
  }

  componentWillUnmount() {
    // Make sure to remove the DOM listener when the component is unmounted.
    window.removeEventListener("resize", this.handleWindowResize);
  }

  handleWindowResize = () => {

    console.log("New window size: ", window.innerWidth, window.innerHeight);

    if (typeof this.state.orientation !== 'undefined') {
      if ( (this.state.orientation === 'landscape' && window.innerWidth < window.innerHeight) ||
           (this.state.orientation === 'portrait' && window.innerWidth > window.innerHeight)
          ) {
              console.log("Reloading window!");
              window.location.reload(false);
            }
    }

    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      orientation: window.innerWidth < window.innerHeight ? 'portrait' : 'landscape'
    }, () => {
      if (typeof this.pageFlip !== 'undefined' && typeof this.pageFlip.pageFlip() !== 'undefined') {
        console.log(this.pageFlip.pageFlip().render.orientation);
        
        //this.flipBook.flipPage().render();
      }
    });
  }

  render() {
      const {width, height} = this.state;

      const padding = 10;

      if (width <= 0 || height <= 0) {
        return null;
      }

      let usePortrait = true;
      let page_width = Math.min(754, (width-(padding*2))/2);
      let page_height = Math.min(1024, (height-(padding*2))/2);
      const ratio = 754.0 / 1024.0;

      if (width >= height) {
        // In landscpe mode we have two pages...
        page_height = Math.min(height-(padding*2), page_width / ratio);
        page_width = page_height*ratio;
        usePortrait = false;
      } else {
        // We are in portrait mode...
        page_width =  width-(padding*2);
        page_height = page_width / ratio;
        
        if (page_height > (height-(padding*2))) {
          page_height = height-(padding*2);
          page_width = page_height * ratio;
        }
      }

      const total_pages = 30;

      const pages = [];

      for (let i=1; i<=total_pages; ++i) {
        let page = ('000' + i);
        page = page.substring( page.length - 3, page.length);

        const styles = {};
        styles.backgroundImage = 'url(https://kiosk.correre.it/latest-magazine/p' + page + '.jpg)';
        const scaleFactor = page_height/1024.0;
        styles.transformOrigin = 'top left';
        styles.transform = 'scale(' + scaleFactor + ')';

        pages.push(<div key={page} className="page"><div className="page-content" style={styles}></div></div>)
      }
      return (
        <>
        <div className="Magazine">
          <HTMLFlipBook
                size="fixed"
                width={page_width}
                height={page_height}
                usePortrait={usePortrait}
                ref={(el) => (this.pageFlip = el)} onChangeOrientation={this.onOrientationChanged}
                onFlip={this.onFlip}
            >
            { pages }
          </HTMLFlipBook>
        </div>
        {/* <div className="sizeInfo">Rendered at {width} x {height} ({page_width.toFixed(0)} x {page_height.toFixed(0)})</div> */}
        <RegistrationDialog open={this.state.registrationOpen} onClose={() => this.setState({ registrationOpen: false })}/>
        </>
      );
  }

  onOrientationChanged = () => {
    console.log("Orientation changed!");
  }

  onOpenRegistration = () => {
    this.setState({ registrationOpen: true });
  }

  onFlip = () => {
    
    // If the registration dialog is open, we just skip this page flip count...
    if (this.state.registrationOpen) {
      return;
    }

    if (this.state.flips + 1 >= 5) {
      this.setState({flips: 0, registrationOpen: true});
    }
    else {
      this.setState({flips: this.state.flips+1});
    }
  }
}


export default Magazine;




